import React from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Nav.css";

const menuIcon = (
  <FontAwesomeIcon className="menu-icon" icon={faBars} size="2x" />
);

function Nav() {
  return (
    <div className="nav-container">
      <div className="logo">NT</div>
      <div>{menuIcon}</div>
    </div>
  );
}

export default Nav;
