import "./App.css";
import Nav from "./Components/Nav/Nav.js";
import profilePic from "./Images/profile.jpg";
import github from "./Images/github.png";
import linkedIn from "./Images/linkedIn.png";
import instagram from "./Images/instagram.png";
import facebook from "./Images/facebook.png";

function App() {
  return (
    <main className="App">
      <Nav className="nav"></Nav>
      <section className="profile">
        <img src={profilePic} alt="" className="img" />
        <p className="profile-intro">Hi, my name is</p>
        <p className="profile-name">Noah Taylor.</p>
        <p className="profile-caption">
          I am an Engineering Student and aspiring Software Developer.
        </p>
        <div className="profile-links-container">
          <a href="https://github.com/noahntaylor" className="link">
            <img src={github} alt="" className="profile-link" />
          </a>
          <a href="https://www.linkedin.com/in/noahntaylor/" className="link">
            <img src={linkedIn} alt="" className="profile-link" />
          </a>
          <a href="https://www.instagram.com/noahntaylor/" className="link">
            <img src={instagram} alt="" className="profile-link" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100008078370188"
            className="link"
          >
            <img src={facebook} alt="" className="profile-link" />
          </a>
        </div>
      </section>
      <section className="section">ABOUT</section>
      <section className="section">WORK</section>
      <section className="section">PROJECTS</section>
      <section className="section">FUN</section>
      <footer className="footer">FOOTER</footer>
    </main>
  );
}

export default App;
